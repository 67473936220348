<template>
  <div class="partner-list flex flex-column gap-4">
    <div class="flex items-center justify-between gap-2 w-100">
      <div class="flex gap-8">
        <div class="flex flex-column gap-1">
          <div>Search:</div>
          <b-form-input
            v-model="filters.search"
            placeholder="Search"
            size="sm"
            autocomplete="off"
            trim
            type="search"
            debounce="500"
            @change="fetchRecords()"
            class="search-field"
          ></b-form-input>
        </div>

      </div>
      <div class="flex gap-2">
        <b-button
          size="sm"
          variant="primary"
          class="text-nowrap"
          @click="createRecord()"
        >
          New supplier
        </b-button>
      </div>
    </div>

    <div class="font-bold">
      Showing {{ records.length | format }} / {{ recordCount | format }} supplier(s)
    </div>

    <table class="table table-google table-records table-highlight">
      <thead>
        <tr>
          <th class="text-nowrap">Partner</th>
          <th class="text-nowrap">E-mail</th>
          <th class="text-nowrap">Phone</th>
          <th class="text-nowrap">IČO</th>
          <th class="text-nowrap">DIČ</th>
          <th class="text-nowrap">Address</th>
          <th class="text-nowrap">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!records && !isLoading">
          <td>No partners available</td>
        </tr>
        <tr
          v-for="record in records"
          :key="record.id"
          class="record"
          :class="{ 'active': lastActiveRecord === record.id }"
        >
          <td class="text-nowrap row-fit">
            {{ record.partner_name }}
            {{ record.partner_subname }}
          </td>
          <td class="text-nowrap row-fit">
            {{ record.partner_email }}
          </td>
          <td class="text-nowrap row-fit">
            {{ record.partner_phone }}
          </td>
          <td class="text-nowrap row-fit">
            {{ record.partner_ico }}
          </td>
          <td class="text-nowrap row-fit">
            {{ record.partner_dic }}
          </td>
          <td>
            {{ record.address_text }}
          </td>
          <td class="row-fit">
            <div class="flex gap-2">
             <b-button
                variant="primary"
                size="sm"
                @click="editRecord(record)"
                :disabled="isLoading"
              >
                Open
              </b-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <b-pagination
      v-model="filters.page"
      :total-rows="recordCount"
      :per-page="filters.per_page"
      @input="fetchRecords(true)"
      :disabled="isLoading"
    ></b-pagination>

    <partner-modal
      v-if="recordModal.showModal"
      :data="recordModal"
      :currencies="currencies"
      :countries="countries"
      @updated="onRecordUpdated"
      @deleted="onRecordDeleted"
    />
  </div>
</template>

<script>
const PartnerModal = () => import('@/components/invoices/PartnerModal.vue');

export default {
  name: 'BurnTesting',
  components: {
    PartnerModal,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      records: [],
      recordCount: 0,
      countries: [],
      currencies: [],
      lastActiveRecord: null,
      filters: {
        page: 1,
        per_page: 50,
        search: '',
      },
      options: {},
      recordModal: {
        showModal: false,
        options: {},
        record: null,
      },
    };
  },
  methods: {
    fetchCountries() {
      this.loadingCount++;
      this.$http
        .get('/country')
        .then((res) => {
          this.options.country = res.body.countries.map((country) => ({
            text: country.country_name,
            value: country.id,
          }));
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch countries: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchCurrencies() {
      this.loadingCount++;
      this.$http
        .get('/currency')
        .then((res) => {
          this.options.currency = res.body.currencies.map((currency) => ({
            text: currency.currency_code,
            value: currency.id,
          }));
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch currencies: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRecordData() {
      this.loadingCount++;
      this.$http
        .get('/partner')
        .query(this.filters)
        .then((res) => {
          this.records = res.body.records;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch records: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRecordCount() {
      this.loadingCount++;
      this.$http
        .get('/partner_count')
        .query(this.filters)
        .then((res) => {
          this.recordCount = res.body.count;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch record count: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRecords(paginate) {
      paginate = paginate === undefined ? false : paginate;
      if (paginate === false) {
        this.fetchRecordCount();
        this.filters.page = 1;
      } else {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 50);
      }
      this.fetchRecordData();
    },
    createRecord() {
      this.loadingCount++;
      this.$http
        .post('/partner')
        .then((res) => {
          this.records.unshift(res.body.record);
          this.fetchRecordCount();
          this.editRecord(res.body.record);
        })
        .catch((err) => {
          this.$toast.error(`Failed to create record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    editRecord(record) {
      this.recordModal.record = record;
      this.recordModal.options = this.options;
      this.recordModal.showModal = true;
      this.lastActiveRecord = record.id;
    },
    onRecordUpdated() {
      this.fetchRecords(true);
    },
    onRecordDeleted(recordId) {
      this.records = this.records.filter((record) => record.id !== recordId);
      this.fetchRecordCount();
    },
  },
  created() {
    this.fetchCountries();
    this.fetchCurrencies();
    this.fetchRecords();
  },
};
</script>

<style lang="scss" scoped>
  .partner-list {
    max-width: 1350px;
  }

  .search-field {
    width: 300px;
  }
</style>
